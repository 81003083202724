<template>
  <div>
    <h1 class="text-gray-800 text-xl" v-text="'Хувийн мэдээллээ бөглөх'"></h1>
    <div class="border-b-2 border-gray-300 my-4"></div>
    <div class="flex flex-col w-96" v-on:keyup.enter="clickedFn">
      <input v-model="staff.firstname" placeholder="Нэр" class="input" />
      <input v-model="staff.lastname" placeholder="Овог" class="input" />
      <input v-model="staff.photo" placeholder="Зураг" class="input" />
      <input v-model="staff.birthday" placeholder="Төрсөн өдөр" class="input" />
      <div class="flex mb-2 mt-2 items-center">
        <!-- <label class="font-semibold" v-text="'Мэйл'"></label> -->
        <input id="male" type="radio" value="male" v-model="staff.gender" />
        <label class="font-semibold mx-2" for="sendEmail">Эрэгтэй</label>
        <input id="female" type="radio" value="female" v-model="staff.gender" />
        <label class="font-semibold mx-2" for="sendInfoEmail">Эмэгтэй</label>
      </div>
      <input v-model="staff.phone" placeholder="Утасны дугаар" class="input" />

      <textarea
        v-model="staff.msg"
        rows="1"
        placeholder="Мессежээ оруулна уу"
        class="input"
      />

      <!-- Хүйс сонгох сонгох "input" хэсэг -->

      <div>
        <select id="address" v-model="staff.address" class="input">
          <option
            v-for="(address, index) in addresses"
            :key="index"
            :value="address"
          >
            {{ address }}
          </option>
        </select>
      </div>

      <!-- Button component -->

      <br />
      <div class="flex">
        <button @click="submit" class="button">Илгээх</button>
        <span>&nbsp;&nbsp;</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      addresses: ["Улаанбаатар", "Эрдэнэт", "Дархан", "Өндөрхаан"],
      staff: {
        lastname: null,
        firstname: null,
        photo: null,
        birthday: null,
        phone: null,
        // age: { type: Number },
        msg: "",
        // sendEmails: [],
        gender: "male",
        address: "Улаанбаатар",
      },
    };
  },
  methods: {
    submit() {
      axios
        .post("createPersonal", {
          fk_useraccountid: this.$store.state.authModule.userId,
          lastname: this.staff.lastname,
          firstname: this.staff.firstname,
          birthday: this.staff.birthday,
          phone: this.staff.phone,
        })
        .then((resp) => {
          this.$router.go();
          console.log(resp.data.successRegistrated[0]);
          localStorage.setItem(
            "info",
            resp.data.successRegistrated[0].fk_useraccountid
          );
        })
        .catch((err) => console.log("error: " + err));
    },
  },
};
</script>

<style>
</style>