<template>
  <div class="p-4">
    <div v-if="$store.state.authModule.info !== null">
      <span class="text-xl">{{ "Нэр: " + firstname }}</span>
    </div>
    <fill-info v-else />
  </div>
</template>

<script>
import axios from "axios";
import FillInfo from "./fillInfo.vue";
export default {
  data() {
    return {
      firstname: "",
    };
  },
  components: {
    FillInfo,
  },
  methods: {},
  created() {
    this.$store.state.authModule.info !== null &&
      axios
        .post(`userPersonal/${this.$store.state.authModule.info}`)
        .then((resp) => {
          console.log(resp);
          this.firstname = resp.data[0].firstname;
        })
        .catch((err) => console.log(err));
  },
};
</script>

<style>
</style>